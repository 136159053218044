<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_from"
                                            label="Start Date"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal_to"
                                        v-model="modal_to"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_to"
                                            label="End Date"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_to"
                                            no-title
                                            @input="modal_to = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-truck"
                                        clearable
                                        dense
                                        solo
                                        v-model="expedition"
                                        :items="expeditions"
                                        item-value="expd_id"
                                        :item-text="item => item.expd_id.trim() + ' | ' + item.expd_name"
                                        label="Expedisi"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getDriverEkspedisi(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-card-account-details"
                                        clearable
                                        dense
                                        solo
                                        v-model="driver"
                                        :items="drivers"
                                        item-value="driver_name"
                                        :item-text="item => item.driver_name.trim()"
                                        label="Driver Expedisi"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_expedition"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-12">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account"
                                        solo
                                        dense
                                        clearable
                                        v-model="customer"
                                        :items="customers"
                                        :search-input.sync="searchCustomer"
                                        cache-items
                                        hide-no-data
                                        hide-details
                                        allow-overflow
                                        label="Customer"
                                        item-value="cust_id"
                                        item-text="cust_name"
                                        class="ma-0 pa-0 border-12"
                                        :loading="loading_customer"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-check"
                                        clearable
                                        dense
                                        solo
                                        v-model="approve"
                                        :items="approves"
                                        item-value="value"
                                        :item-text="item => item.descr.trim()"
                                        label="Approved"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="4" lg="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="deliveries"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="475"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Data </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <v-btn text color="success" v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.aprv_flag.trim() == 'Y'">Approved</v-btn>
                                        <v-btn text color="error" v-else>Not Approved</v-btn>
                                    </template>
                                    <template v-slot:[`item.dt_trx`]="{ item }">
                                        {{ (new Date(new Date(item.dt_trx) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                    </template>
                                    <template v-slot:[`item.wgt`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.wgt / 1000) }} TON
                                    </template>
                                    <template v-slot:[`item.length`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.length) }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-menu
                                            bottom
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    dark
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="grey"
                                                    class="text-center"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item @click="detailItem(item)">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-magnify</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Approve</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- <v-list-item v-if="item.dt_aprv == '1900-01-01 00:00:00.000' && item.aprv_flag.trim() == ''">
                                                    <v-list-item-icon>
                                                        <v-icon color="success">mdi-check</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Approve</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item> -->
                                            </v-list>
                                        </v-menu>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="900px"
                    hide-overlay
                    transition="dialog-bottom-transition"
                    style="border-radius: 0 !important"
                >
                    <v-card class="p-2" style="border-radius: 0 !important">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Detail
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="[dialog = false]"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-card outlined>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Deliv ID : {{ detail ? detail.deliv_id : '-' }}
                                                </v-col>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Order ID : {{ detail ? detail.order_id : '-' }}
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    Customer : {{ detail ? detail.cust_name : '-' }}
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    Expedisi : {{ detail ? detail.expd_name : '-' }}
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    Driver / No POL : {{ detail ? detail.driver : '-' }} / {{ detail ? detail.no_pol : '-' }}
                                                </v-col>
                                                <v-col class="col-12">
                                                    Alamat : {{ detail ? detail.cons_name : '-' }} - {{ detail ? detail.address1 : '-' }}
                                                </v-col>
                                            </v-row>
                    
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                <v-card outlined class="mt-3">
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        dense
                                                        :headers="header_details"
                                                        :items="details"
                                                        class="elevation-1"
                                                        :items-per-page="30"
                                                        :footer-props="{
                                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                        }"
                                                        :loading="$store.state.overlay"
                                                        height="350"
                                                        fixed-header
                                                        :divider="true"
                                                        :light="true"
                                                        :search="searchItem" 
                                                        :item-class="tr_datatable_outstanding"
                                                    >    
                                                        <template v-slot:top>
                                                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                                                <div class="d-flex w-100">
                                                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail </h6>
                                                                    <v-spacer></v-spacer>
                                                                    <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                </div>
                                                            </v-toolbar>
                                                        </template>
                                                        <template v-slot:[`item.wgt`]="{ item }">
                                                            {{ $store.getters.convertToCurrencyUs(item.wgt / 1000) }} TON
                                                        </template>
                                                        <template v-slot:[`item.wgt_ord`]="{ item }">
                                                            {{ $store.getters.convertToCurrencyUs(item.wgt_ord / 1000) }} TON
                                                        </template>
                                                        <template v-slot:[`item.wgt_shipped`]="{ item }">
                                                            {{ $store.getters.convertToCurrencyUs(item.wgt_shipped / 1000) }} TON
                                                        </template>
                                                        <template v-slot:[`item.sisa_order`]="{ item }">
                                                            {{ $store.getters.convertToCurrencyUs(item.sisa_order / 1000) }} TON
                                                        </template>
                                                        <template v-slot:[`item.actions`]="{ item }">
                                                            <v-menu
                                                                bottom
                                                                left
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        color="grey"
                                                                        class="text-center"
                                                                    >
                                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list dense>
                                                                    <v-list-item @click="detailItemUnit(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon>mdi-magnify</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>Detail Unit</v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        dense
                                                        :headers="header_detail_units"
                                                        :items="detail_units"
                                                        class="elevation-1"
                                                        :items-per-page="30"
                                                        :footer-props="{
                                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                        }"
                                                        :loading="$store.state.overlay"
                                                        height="350"
                                                        fixed-header
                                                        :divider="true"
                                                        :light="true"
                                                        :search="searchItemUnit" 
                                                        :item-class="tr_datatable"
                                                    >    
                                                        <template v-slot:top>
                                                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                                                <div class="d-flex w-100">
                                                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail Unit</h6>
                                                                    <v-spacer></v-spacer>
                                                                    <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemUnit" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                </div>
                                                            </v-toolbar>
                                                        </template>
                                                        <template v-slot:[`item.wgt`]="{ item }">
                                                            {{ $store.getters.convertToCurrencyUs(item.wgt / 1000) }} TON
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-card-text>
                        <v-card-actions v-if="detail && aprvAccess">
                            <v-container fluid>
                                <v-row align="end" justify="end">
                                    <v-col xs="6" sm="6" md="3" class="col-6">
                                        <v-btn v-if="detail.dt_aprv == '1900-01-01 00:00:00.000' && detail.aprv_flag.trim() == '' && aprvAccess" class="border-12" block color="success" elevation="2" @click="setApprove('approve')"><v-icon>mdi-check</v-icon> Approve</v-btn>
                                        <v-btn v-else-if="detail.dt_aprv != '1900-01-01 00:00:00.000' && detail.aprv_flag.trim() == 'Y' && aprvAccess" class="border-12" block color="warning" elevation="2" @click="setApprove('unapprove')"><v-icon>mdi-check</v-icon> UnApprove</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Approval',
                    disabled: true,
                },
                {
                    text: 'Delivery Approval',
                    disabled: true,
                }
            ],
            modal: false,
            modal_to: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date_to: '',
            searchItemTrans: '',
            expeditions: [],
            expedition: '',
            loading_expedition: false,
            drivers: [],
            driver: '',
            loading_customer: false,
            customers: [],
            customer: '',
            searchCustomer: null,
            headers: [
                { text: '', value: 'actions', width:50, align: 'left' },
                { text: 'Stat Approval', value: 'status', width:150, align: 'left' },
                { text: 'Deliv ID', value: 'deliv_id', width:150, align: 'left' },
                { text: 'Order ID', value: 'order_id', width:150, align: 'left' },
                { text: 'Trans Date', value: 'dt_trx', width:150, align: 'left' },
                { text: 'Customer', value: 'cust_name', width:150, align: 'left' },
                { text: 'Cons Name', value: 'cons_name', width:250, align: 'left' },
                { text: 'Alamat', value: 'address1', width:250, align: 'left' },
                { text: 'Weight', value: 'wgt', width:150, align: 'right' },
                { text: 'Expedisi', value: 'expd_name', width:150, align: 'left' },
                { text: 'Driver', value: 'driver', width:150, align: 'left' },
                { text: 'No Pol', value: 'no_pol', width:150, align: 'left' },
                // { text: 'Length', value: 'length', width:150, align: 'left' },
                { text: 'Status', value: 'stat', width:150, align: 'left' },

            ],
            deliveries: [],
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved',
                    value: 'approve'
                },
                {
                    descr: 'Approved',
                    value: 'approved'
                },
            ],
            approve: '',
            header_details:[
                { text: '', value: 'actions', width:50, align: 'left' },
                { text: 'Item Num', value: 'item_num', width:50, align: 'right' },
                { text: 'Product Code', value: 'prod_code', width:150, align: 'left' },
                { text: 'Product', value: 'descr_product', width:250, align: 'left' },
                { text: 'Weight', value: 'wgt', width:120, align: 'right' },
                { text: 'Order Weight', value: 'wgt_ord', width:150, align: 'right' },
                { text: 'Shipped Weight', value: 'wgt_shipped', width:150, align: 'right' },
                { text: 'Remaining Order Weight', value: 'sisa_order', width:150, align: 'right' },
            ],
            details: [],
            searchItem: '',
            detail: null,
            dialog: false,
            aprvAccess: '',
            detailUnit: null,
            header_detail_units: [
                { text: 'Coil ID', value: 'coil_id', width:100, align: 'left' },
                { text: 'Brand Coat', value: 'brand_coat_name', width:150, align: 'left' },
                { text: 'Product', value: 'nama_produk', width:250, align: 'left' },
                { text: 'Weight', value: 'wgt', width:120, align: 'right' },
            ],
            detail_units: [],
            searchItemUnit: ''
        }
    },
    computed:{
    },
    watch: {
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        await this.getEnvConf()
        await this.getEkspedisi()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable_outstanding(item) {
            var rowClass = 'tr_datatable';
            if (this.detailUnit) {
                if(this.detailUnit.deliv_id.trim() === item.deliv_id.trim() && this.detailUnit.deliv_seq === item.deliv_seq){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            } else {
                rowClass = 'tr_datatable'
            }
            return rowClass;
        },
        tr_datatable() {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.date_to = ''
            this.expedition = ''
            this.driver = ''
            this.customer = ''
            this.approve = ''

            this.deliveries = []
            this.details = []
            this.detail = null
        },
        async getEnvConf(){
            const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SR&appl_id=WEBSR&var_id=APRVDELIV&groupid=${this.$store.state.sr.group_id}`, null, false, false, false)  
            
            if (respDataRegion.status == 200) {
                this.aprvAccess = respDataRegion.data.data ? respDataRegion.data.data : ''
            } else {
                this.aprvAccess = ''
            }
        },
        async getEkspedisi(){
            await axios.get(`${process.env.VUE_APP_URL}/v2/master/sr/expedisi?expd_id=${this.expedition ? this.expedition : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.expeditions = res.data
            });
        },
        async getDriverEkspedisi(event){
            if (event == '' || event == null) {
                this.driver = ''
            } else {
                this.loading_expedition = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/sr/driver_expedisi?expd_id=${this.expedition ? this.expedition : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_expedition = false
                    this.drivers = res.data
                });
            }
        },
        async getCustomer(event){
            if (event == '' || event == null) {
                this.customer = ''
            } else {
                this.loading_customer = true
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/customer?search=${event}&customer_id=${this.customer ? this.customer : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_customer = false
                    this.customers = res.data
                });
            }
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            var reqBody = {
                'expd_id': this.expedition ? this.expedition : '',
                'driver': this.driver ? this.driver : '',
                'cust_id': this.customer ? this.customer : '',
                'txtApproved': this.approve ? this.approve : '',
                'start_date': start_date ? start_date : '',
                'end_date': end_date ? end_date : '',
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/delivery', reqBody, false, false, false)
            if (respData.status === 200) {
                this.deliveries = respData.data
                this.$store.dispatch('setOverlay', false)  
            }
        },
        async detailItem(item){
            this.$store.dispatch('setOverlay', true)  
            this.detail = item

            var reqBody = {
                'mill_id': item.mill_id ? item.mill_id.trim() : '',
                'deliv_id': item.deliv_id ? item.deliv_id.trim() : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/delivery/show', reqBody, false, false, false)
            if (respData.status === 200) {
                this.details = respData.data
                this.dialog = true
            }
            this.$store.dispatch('setOverlay', false)  
        },
        async detailItemUnit(item){
            this.detailUnit = item

            this.$store.dispatch('setOverlay', true)  

            var reqBody = {
                'mill_id': item.mill_id ? item.mill_id.trim() : '',
                'deliv_id': item.deliv_id ? item.deliv_id.trim() : '',
                'deliv_seq': item.deliv_seq ? item.deliv_seq : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/delivery/showDelivUnit', reqBody, false, false, false)
            if (respData.status === 200) {
                this.detail_units = respData.data
            }
            this.$store.dispatch('setOverlay', false)  
        },
        async setApprove(aprv){

            this.$store.dispatch('setOverlay', true)  

            var reqBody = {
                'mill_id': this.detail.mill_id ? this.detail.mill_id.trim() : '',
                'deliv_id': this.detail.deliv_id ? this.detail.deliv_id.trim() : '',
                'approve': aprv,
            }

            const respData = await backendApi.fetchCore('/api/v3/sr/delivery/approve', reqBody, false, false, false)
            if (respData.status === 200) {
                this.dialog = false
                this.$store.dispatch('setOverlay', false) 
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve",
                    visible: true
                };
                await this.searchFunction()
            }
        },
        async exportExcel(){
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },    
}
</script>